export const ToggleImg = (props: {
  active: boolean
  src: string
  scr_off: string
  alt?: string
  classname?: string
}) => {
  const { active, src, scr_off, alt = '', classname } = props
  return <img src={active ? src : scr_off} alt={alt} className={classname} />
}
