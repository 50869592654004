import { type JSX, lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoadPage from "@/components/LoadPage/LoadPage";
import { ToggleImg } from "@/components/ToggleImg/ToggleImg";
// const Frens = lazy(() => import("@/pages/Frens/Frens"));
const Rank = lazy(() => import("@/pages/Rank/Rank"));
const Tasks = lazy(() => import("@/pages/Tasks/Tasks"));
const Recruit = lazy(() => import("@/pages/Recruit/Recruit"));
const Awards = lazy(() => import("@/pages/Awards/Awards"));
const DmageRank = lazy(() => import("@/pages/DamageRank/DamageRank"));
const Profile = lazy(() => import("@/pages/Profile/Profile"));
const Loot = lazy(() => import("@/pages/Loot/Loot"));
const Rules = lazy(() => import("@/pages/Rules/Rules"));
const Notpc = lazy(() => import("@/pages/Notpc/Notpc"));
const preloadRoutes = () => {
  const otherRoutes = [
    // () => import("@/pages/Frens/Frens"),
    () => import("@/pages/Rank/Rank"),
    () => import("@/pages/Tasks/Tasks"),
    () => import("@/pages/Recruit/Recruit"),
    () => import("@/pages/Awards/Awards"),
    () => import("@/pages/DamageRank/DamageRank"),
    () => import("@/pages/Profile/Profile"),
    () => import("@/pages/Loot/Loot"),
    () => import("@/pages/Rules/Rules"),
    () => import("@/pages/Notpc/Notpc"),
  ];
  const timer = setTimeout(() => {
    otherRoutes.forEach((route) => route());
    clearTimeout(timer);
  }, 1300);
};
const Home = lazy(() =>
  import("@/pages/Home/Home").then((module) => {
    preloadRoutes();
    return module;
  })
);

interface Route {
  path: string;
  element: JSX.Element;
  title?: string;
  tabbarTitle?: string;
  icon?: JSX.Element | string | ((active: boolean) => JSX.Element);
  isnotShowNavbar?: boolean;
}
const routes: Route[] = [
  {
    path: "/home",
    element: <Home />,
    title: "Home",
    tabbarTitle: "Home",
    icon: (active: boolean) => (
      <ToggleImg
        active={active}
        src={"/imgs/tabbar/home.png"}
        scr_off={"/imgs/tabbar/home_1.png"}
        classname="w-[16px] h-[16px]"
      ></ToggleImg>
    ),
  },
  {
    path: "/frens",
    element: <Profile />,
    title: "Frens",
    tabbarTitle: "Earn",
    icon: (active: boolean) => (
      <ToggleImg
        active={active}
        src={"/imgs/tabbar/friend.png"}
        scr_off={"/imgs/tabbar/friend_1.png"}
        classname="w-[16px] h-[16px]"
      ></ToggleImg>
    ),
  },
  {
    path: "/tasks",
    element: <Tasks />,
    title: "Tasks",
    tabbarTitle: "Task",
    icon: (active: boolean) => (
      <ToggleImg
        active={active}
        src={"/imgs/tabbar/task.png"}
        scr_off={"/imgs/tabbar/task_1.png"}
        classname="w-[14px] h-[14px]"
      ></ToggleImg>
    ),
  },
  {
    path: "/rank",
    element: <Rank />,
    title: "Rank",
    tabbarTitle: "Rank",
    icon: (active: boolean) => (
      <ToggleImg
        active={active}
        src={"/imgs/tabbar/rank.png"}
        scr_off={"/imgs/tabbar/rank_1.png"}
        classname="w-[18px] h-[16px]"
      ></ToggleImg>
    ),
  },
  {
    path: "/recruit",
    element: <Recruit />,
    isnotShowNavbar: false,
  },
  {
    path: "/awards",
    element: <Awards />,
    isnotShowNavbar: false,
  },
  {
    path: "/damage-rank",
    element: <DmageRank />,
    isnotShowNavbar: false,
  },
  {
    path: "/profile",
    element: <Profile />,
    isnotShowNavbar: false,
  },
  {
    path: "/rules",
    element: <Rules />,
    isnotShowNavbar: false,
  },
  {
    path: "/loot",
    element: <Loot />,
    isnotShowNavbar: false,
  },
  {
    path: "/notpc",
    element: <Notpc />,
    isnotShowNavbar: false,
  },
];

const tabsRoute = routes.slice(0, 4);

const AppRoutes = () => (
  <Suspense fallback={<LoadPage currentStep={70}></LoadPage>}>
    <Routes>
      {routes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route path="*" element={<Navigate to="/home" />} />
      {/* <Route path="*" element={<Navigate to="/profile" />} /> */}
    </Routes>
  </Suspense>
);

export { routes, tabsRoute };
export default AppRoutes;
