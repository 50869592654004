import { create } from 'zustand'
type BaseState = {
  imgObj: Record<string, string>
  palmReoslveImg: string
  currentScore: number
}
type BaseAction = {
  updateImgobj: (bears: BaseState['imgObj']) => void
  updatePalmImg: (bears: BaseState['palmReoslveImg']) => void
  updateUserCurrentScore: (bears: BaseState['currentScore']) => void
}

export const useBaseDataStore = create<BaseState & BaseAction>((set) => ({
  imgObj: {},
  palmReoslveImg: '',
  currentScore: 0,
  updateImgobj: (newObj) => set({ imgObj: newObj }),
  updatePalmImg: (newString) => set({ palmReoslveImg: newString }),
  updateUserCurrentScore: (currentScore) => set({ currentScore: currentScore }),
}))
