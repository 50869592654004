import request from "./config";
import { heroStyleList } from "@/pages/Home/dic";
import { MyToast } from "@/utils";

type TypeRecruitHeroParams = {
  number: number;
};

export type heroItem = {
  extra: {
    icon: string;
    level: number;
    name: string;
    harm_range_max: number;
    harm_range_min: number;
  };
  id: number;
  item_id?: number;
  item_name?: string;
  item_type?: number;
  number?: number;
};

export const getUserHeroList = async () => {
  try {
    const res = await request.get("/api/user/items");
    if (res.code === 0) {
      const list = res.data
        .filter((item: heroItem) => item.item_type === 2)
        .slice(0, 10);
      return list;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

// 打boss
export const attackBoss = async (params: { heros: number[] }) => {
  const res = await request.post("/api/app/boss/fight", params);
  if (res.code === 0) {
    return res.data;
  }
  return null;
};

export const getRecruitHero = async (params: TypeRecruitHeroParams) => {
  try {
    const res = await request.post("/api/app/boss/exchange", params);
    if (res.code === 0) {
      console.log(res.data, 111);
      return res.data.map((item: heroItem) => {
        const heroInfo = heroStyleList[item?.extra?.level - 1];
        return {
          ...heroInfo,
          img: `/imgs/character/${item?.extra?.icon}`,
          name: item?.item_name || `Pigg ${item?.extra?.level}`,
        };
      });
    } else {
      MyToast(res.message);
      return [];
    }
  } catch (error) {
    console.log(error, 111);
  }
};
