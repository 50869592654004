// useRem.ts
import { useEffect } from "react";
import { retrieveLaunchParams } from "@telegram-apps/sdk";

const useRem = (designWidth: number = 375, baseSize: number = 16): void => {
  const launchParams = retrieveLaunchParams();
  const isPC =
    launchParams.platform === "tdesktop" || launchParams.platform === "macos";
  const setRemUnit = (): void => {
    const html = document.documentElement;
    const screenWidth = html?.clientWidth || window?.innerWidth;
    const scale = screenWidth / designWidth;
    console.log(scale, "scale", isPC);

    // if (isPC) {
    //   html.style.fontSize = `${14 * scale}px`;
    // } else {
    //   html.style.fontSize = `${baseSize * scale}px`;
    // }
    html.style.fontSize = `${14 * scale}px`;
  };

  useEffect(() => {
    setRemUnit();
    window.addEventListener("resize", setRemUnit);
    return () => {
      window.removeEventListener("resize", setRemUnit);
    };
  }, [designWidth, baseSize]);
};

export default useRem;
