export const heroStyleList = [
  {
    border: "#a4d433",
    level: 1,
    bg: "/imgs/character/border/bg_1.png",
    flag: "D",
  },
  {
    border: "#6b9dd3",
    level: 2,
    bg: "/imgs/character/border/bg_2.png",
    flag: "C",
  },
  {
    border: "#cb5aff",
    level: 3,
    bg: "/imgs/character/border/bg_3.png",
    flag: "B",
  },
  {
    border: "#f1d511",
    level: 4,
    bg: "/imgs/character/border/bg_4.png",
    flag: "A",
  },
  {
    border: "#ff8e15",
    level: 5,
    bg: "/imgs/character/border/bg_5.png",
    flag: "S",
  },
];

// 写一个节流
