import { DotLoading } from "antd-mobile";
type PropsType = {
  currentStep?: number;
};
export default function LoadPage(props: PropsType) {
  const { currentStep = 0 } = props;
  return (
    <div className="flex items-center flex-col w-screen h-screen justify-around py-[100px]">
      <img src="/imgs/logo.png" className="w-[252px] h-[252px]" alt="" />
      <div className="flex text-white text-[25px]">
        <div>Loading</div>
        <DotLoading color="currentColor" />
      </div>
      <div className="relative w-[312px] h-[20px] bg-[#4B3F10] rounded">
        <div
          className="absolute h-full bg-thc rounded transition-all duration-300 ease-out"
          style={{
            width: `${Math.min(currentStep, 100)}%`,
          }}
        />
        <div
          className="absolute  text-center text-[12px] leading-[20px] text-black"
          style={{
            left: `${currentStep}%`,
            transform: "translateX(-110%)",
          }}
        >
          Loading: {currentStep.toFixed(1)}%
        </div>
        <img
          src="/imgs/loading_per.png"
          className="absolute bottom-[20px]  w-[32px] h-[32px]"
          style={{
            left: `${currentStep}%`,
            transform: "translateX(-50%)",
          }}
          alt=""
        />
      </div>
    </div>
  );
}
