import { create } from "zustand";
import { GameData } from "@/api/user";
import { type heroItem } from "@/api/hero";
type BaseState = {
  initInfo: GameData;
  heroList: heroItem[];
};

type BaseAction = {
  updateInitInfo: (initInfo: GameData) => void;
  updateHeroList: (heroList: heroItem[]) => void;
};

export const useInitStore = create<BaseState & BaseAction>((set) => ({
  initInfo: {
    currency: {
      gold: "0",
      score: "0",
    },
    buy_times: 0,
    boss_current_info: {
      id: "1",
      total_hp: "30000",
      name: "",
      start_time: "",
      bonus_pool: "0",
      remain_hp: "0",
      icon: "",
    },
    notice: [],
    damage_times: 10,
    damage_total_times: 10,
    game_config: {
      upgrade_amount: 0.5,
      a_gold_amount: 100,
    },
  },
  heroList: [],
  updateInitInfo: (initInfo) => set({ initInfo: initInfo }),
  updateHeroList: (heroList) => set({ heroList }),
}));
