import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './mockEnv.ts'
import App from './root/App'
import './config/css/global.css'

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
)
