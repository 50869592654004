import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
const locales = import.meta.glob('/locales/*.json', { eager: true });
type LanguageType = Record<string, { translation: Record<string,string>  }>

const resources = Object.keys(locales).reduce((acc, key) => {
  const lang = key.match(/\/locales\/(.+)\.json$/)?.[1];
  const code = lang
  if (code) {
    acc[code] = {
      translation: (locales[key] as { default: Record<string,string> })?.default
    };
  }
  return acc;
}, {} as LanguageType);

const convertToArray = (resources: LanguageType) => {
  return Object.entries(resources).map(([key, value]) => {
    return {
      language: value.translation?.language,
      value: key,
    };
  });
};
export const languages = convertToArray(resources as LanguageType);

try {
  const initLanguage = localStorage.getItem('i18nextLng_code') || 'en';
  i18n
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: initLanguage,
    detection: {
      // caches: ['localStorage', 'sessionStorage', 'cookie'],
      caches: [],
    }
  });
} catch (error) {
  console.log(error,'use i18n error');
  
}

export default i18n;
