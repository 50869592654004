import { create } from "zustand";
interface UserInfo {
  [key: string]: string | number | boolean | undefined;
}

type BaseState = {
  userinfo: UserInfo;
  baseInfo: UserInfo;
  isGuideStep: number;
};

type BaseAction = {
  updateUserinfo: (bears: BaseState["userinfo"]) => void;
  updateBaseinfo: (bears: BaseState["userinfo"]) => void;
  updateUserinfoKey: (
    info: Partial<{ [key: string]: string | number | boolean }>
  ) => void;
  updateGuideStep: (step: number) => void;
};

export const useUserDataStore = create<BaseState & BaseAction>((set) => ({
  userinfo: {
    banance: 95217,
    rank: "23.2M",
    ticket: 0,
    isHasPoint: true,
    isDailyFristLogin: false,
    currentLoginReward: 200,
    currentAwardDay: 3,
    isWatched: false,
    blockId: "475",
    isBalanceJump: false,
    isBindWallet: false,
    palmScore: 874,
    isGuideStep: -1,
    isRecruitGuide: false,
  },
  baseInfo: {},
  isGuideStep: -1,
  updateUserinfo: (userinfo) => set({ userinfo: userinfo }),
  updateBaseinfo: (baseInfo) => set({ baseInfo: baseInfo }),
  updateUserinfoKey: (info) => {
    set((state) => ({
      userinfo: {
        ...state.userinfo,
        ...info,
      },
    }));
  },
  updateGuideStep: (step) => set({ isGuideStep: step }),
}));
