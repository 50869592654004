import {
  initSwipeBehavior,
  initClosingBehavior,
  initViewport,
} from '@telegram-apps/sdk'
import { useEffect } from 'react'

export default function UseInitTgInit() {
  const [swipeBehavior] = initSwipeBehavior()
  const [closingBehavior] = initClosingBehavior()
  const [viewport] = initViewport()

  useEffect(() => {
    const init = async () => {
      try {
        swipeBehavior.disableVerticalSwipe()
        closingBehavior.enableConfirmation()
        const vp = await viewport
        if (!vp.isExpanded) {
          vp.expand()
        }
      } catch (error) {
        console.log(error, 'error')
      }
    }
    init()
  }, [swipeBehavior, closingBehavior, viewport])
}
