import { getAvatar } from "@/utils";
import request from "./config";
import { setStorage } from "@/utils/storage";

export const getUserInfo = async () => {
  return await request.get("/api/user");
};

export const userLogin = async (inviter_tgid: string | number = "") => {
  try {
    const res = await request.post("/api/user/login", { inviter_tgid });
    if (res.code === 0 && res?.data?.auth_token) {
      setStorage("token", res.data.auth_token);
      return res.data;
    }
    return;
  } catch (error) {
    console.log(error);
    return;
    // return {
    //   auth_token:
    //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3NjIyMzY5NjgsInRnaWQiOjY3NTg5ODU4NTZ9.qmXQhop8tx9zPl5ro4smEQhZoQUrqzwuFW7K5wYLeyI",
    // };
  }
};

type PramsType = {
  palmistry_pic: string;
  reward: number;
  wallet?: string | number;
};
export const setUserPalmInfo = async (params: PramsType) => {
  try {
    const res = await request.post("/api/user/palmistry-analyze", params);
    if (res.code === 0) {
      return res.data;
    }
    return {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const putUserInfo = async (wallet: PramsType["wallet"]) => {
  try {
    const res = await request.put("/api/user", {
      wallet,
    });
    if (res.code === 0) {
      return res.data;
    }
    return {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

type PramsDailyLoginRewardType = {
  is_watch_ad: boolean;
};
export const getDailyLoginReward = async (
  params: PramsDailyLoginRewardType
) => {
  try {
    const res = await request.post("/api/user/consecutive", params);
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getDailyRewardInfo = async () => {
  try {
    const res = await request.get("/api/user/consecutive");
    if (res.code === 0) {
      return res.data;
    }
    return {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

export type TypeFrens = {
  tgid: number;
  nickname: string;
  score: number;
};
export const getFrens = async (page: number) => {
  try {
    const res = await request.get("/api/user/invite", {
      page,
      size: 100,
    });
    if (res?.code === 0) {
      const list = res?.data?.data?.map((item: TypeFrens) => {
        return {
          ...item,
          avatar: getAvatar(item.tgid),
        };
      });
      const total = res.data.total?.all_total;
      return { list, total };
    }
    return { list: [], total: 0 };
  } catch (error) {
    console.log(error);
    return { list: [], total: 0 };
  }
};

interface Currency {
  gold: string;
  score: string;
}

interface BossCurrentInfo {
  id: string;
  total_hp: string;
  name: string;
  start_time: string;
  bonus_pool: string;
  remain_hp: string;
  icon: string;
}

export interface GameData {
  currency: Currency;
  boss_current_info: BossCurrentInfo;
  notice: string[];
  damage_times: number;
  damage_total_times: number;
  game_config: {
    upgrade_amount: number;
    a_gold_amount: number;
  };
  buy_times: number;
}
export const getUserInitInfo: () => Promise<GameData> = async () => {
  try {
    const res = await request.get("/api/init");
    if (res.code === 0) {
      return res.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const createOrder = async (params: {
  amount: number;
  remark: string;
}) => {
  return await request.post("/api/order", params);
};
